<template>
  <section>
    <div class="grid-container">
      <div class="grid-item">
        <welcome :userData="userData" />
        <filtro :isLoading="isLoading" class="mt-3" @buscar="buscar($event)" />
        <statisticsPatients
          class="mt-3"
          :userData="userData"
          :dataDashboard="dataDashboard" />
        <averages class="mt-3" :averages="averages" :dates="fechas" />
      </div>
      <div class="grid-item">
        <statistics :stadistics="stadistics" />
        <totalPatiens
          class="mt-3"
          :userData="userData"
          :dataDashboard="dataDashboard"
          :fechas="fechas" />
        <membership class="mt-3" :userData="userData" :memberships="sumMembership" />
      </div>
    </div>
  </section>
</template>

<script>
import Welcome from '../components/welcome.vue'
import filtro from '../components/filter'
import statisticsPatients from '../components/statisticsPatients'
import totalPatiens from '../components/totalPatiens'
import statistics from '../components/statistics'
import averages from '../components/averages'
import membership from '../components/membership'

export default {
  components: {
    averages,
    Welcome,
    filtro,
    statisticsPatients,
    totalPatiens,
    statistics,
    membership,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
      required: false,
    },
    dataDashboard: {
      type: Object,
      default: () => {},
      required: false,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
      required: false,
    },
  },
  data() {
    return {
      averages: [],
      stadistics: [],
      fechas: {
        start: '',
        end: '',
      },
      sumMembership: { NP: 0, NC: 0 },
    }
  },
  watch: {
    dataDashboard() {
      this.stadistics = [
        {
          description:
            'Average diario del periodo de la fecha selecionada divido en los dias de ese periodo',
          value: parseInt(this.dataDashboard.average.income_days),
          title: 'Daily Average Income',
        },
        {
          description: 'Suma total del periodo de la fecha selecionada',
          value: parseInt(this.dataDashboard.totals.income),
          title: 'Total Income',
        },
      ]
      this.averages = [
        {
          label: 'AVG_TOTAL',
          title: `Average total $${this.dataDashboard.average.total}`,
          averages: this.dataDashboard.average.total_daily,
        },
        {
          label: 'AVG_NC',
          title: `Average NC $${this.dataDashboard.average.nc}`,
          averages: this.dataDashboard.average.nc_daily,
        },
        {
          label: 'AVG_NP',
          title: `Average NP $${this.dataDashboard.average.np}`,
          averages: this.dataDashboard.average.np_daily,
        },
      ]
      this.sumMembership = this.dataDashboard.memberships
    },
  },
  methods: {
    buscar(filtro) {
      this.fechas = {
        start: filtro.start,
        end: filtro.end,
      }
      this.$emit('buscar', filtro)
    },
  },
}
</script>

<style scoped lang="scss">
@import '../assets/cssDashboards.css';
</style>
