<template>
  <section id="dashboard">
    <div v-if="userData.roles[0].id == 1">
      <superAdmin :isLoading="isLoading" :userData="userData" :dataDashboard="dataDashboard" @buscar="buscar($event)" />
    </div>
    <div v-if="userData.roles[0].id == 2">
      <admin :isLoading='isLoading' :userData="userData" :dataDashboard="dataDashboard" @buscar="buscar($event)" />
    </div>
    <div v-if="userData.roles[0].id == 3">
      <oma :isLoading='isLoading' :userData="userData" :dataDashboard="dataDashboard" @buscar="buscar($event)" />
    </div>
    <div v-if="userData.roles[0].id == 4">
      <nurse :isLoading='isLoading' :userData="userData" :dataDashboard="dataDashboard" @buscar="buscar($event)" />
    </div>
    <div v-if="userData.roles[0].id == 5">
      <frontdesk :isLoading='isLoading' :userData="userData" :dataDashboard="dataDashboard" @buscar="buscar($event)" />
    </div>
  </section>
</template>

<script>
import axios from "@/core/services/apiInsurance/dashboard";
import superAdmin from "./superAdmin/superAdmin.vue";
import admin from "./admin/admin.vue";
import frontdesk from "./frontdesk/frontdesk.vue";
import nurse from "./nurse/nurse.vue";
import oma from "./oma/oma.vue";

export default {
  components: {
    // admin,
    // frontdesk,
    // nurse,
    // oma,
    superAdmin,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      dataDashboard: {},
      isLoading: false,
    };
  },
  created() {
    if (this.userData.roles[0].id != 2) this.buscar({ start: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-1`, end: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}` });
  },
  methods: {
    buscar({ start, end, facilities }) {
      this.isLoading = true;
      const datarda = {
        start_date: start,
        end_date: end,
        facilities
      };
      if (this.userData.roles[0].id != 1 && this.userData.roles[0].id != 2) datarda.user_id = this.userData.id
      axios.getData(datarda).then(({ data }) => {
        this.dataDashboard = data;
        this.isLoading = false;
      });
    },
  },
};
</script>
